<template>
    <v-container>
        <v-row>
            <v-col cols="2" class="pa-1">
                <span>Câu hỏi</span>
            </v-col>
            <v-col cols="10" class="pa-1">
                <div v-if="detailAnswer.ask" v-html="detailAnswer.ask.content"
                     style="padding: 5px;border: 1px solid #9e9e9e; border-radius: 5px"></div>
            </v-col>
        </v-row>
        <v-row class="my-10">
            <v-col cols="2" class="pa-1">
                <span>Câu trả lời </span>
            </v-col>
            <v-col cols="10" class="pa-1">
                <ValidationProvider
                        name="content"
                        rules="required"
                        v-slot="{ errors }"
                >
                    <div style="border: 1px solid #c4c4c4; padding: 10px;margin-bottom: 20px">
                        <math-jax-preview :formula="detailAnswer.content"></math-jax-preview>
                    </div>
                    <ck-content
                            :content="detailAnswer.content"
                            @getData="updateContent"
                            v-model="detailAnswer.content"
                    ></ck-content>
                    <math-jax-editor
                            :formula_prop="formula"
                            :image_prop="detailAnswer.image"
                            @addFormula="addFormula"
                            @image="detailAnswer.image = $event"
                            @delImage="delImage"
                    ></math-jax-editor>
                    <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
                </ValidationProvider>

            </v-col>
        </v-row>
        <v-row class="mb-5">
            <v-col cols="2" class="pa-1">
                <span> Đăng bởi </span>
            </v-col>
            <v-col cols="10" class="pa-1">
                <span v-if="detailAnswer.user">{{ detailAnswer.user.email }}</span>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
// import {mapGetters} from "vuex";

import HnrInput from "@/components/elements/hnr-input";
// import HnrAreaInput from "@/components/elements/hnr-area-input";
// import HnrDatePicker from "@/components/elements/hnr-date-picker";
// import {SET_LOADING} from "@/store/loading.module";
import MathJaxEditor from "@/components/math/MathJaxEditor";
import CkContent from "@/components/ck-content.vue";
import MathJaxPreview from "@/components/math/MathJaxPreview.vue";

export default {
    data() {
        return {
            formula: "",
            dialog: false,
            detailAnswer: {},
            selected: [],
            value: 0,
            search: null,
            singleSelect: true,
            suggestNewCategories: [],
            headers: [
                {
                    text: "id",
                    align: "start",
                    sortable: true,
                    value: "id",
                },
                {
                    text: "Name",
                    align: "start",
                    sortable: true,
                    value: "name",
                },
            ],
        };
    },
    props: {
        answer: Object,
        subjects: Array,
        grades: Array,
        slug_message: {type: String},
    },
    watch: {
        answer: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.detailAnswer = newVal;
                this.renderMathJax()
            },
        },
    },
    components: {
        HnrInput,
        // HnrAreaInput,
        // HnrDatePicker,
        CkContent,
        MathJaxEditor,
        MathJaxPreview
    },
    methods: {
        delImage() {
            this.detailAnswer.image = ''
            this.$emit('delImage', true)
        },
        addFormula(value) {
            this.detailAnswer.content += value;
        },

        getPicker(val) {
            this.detailAnswer.created_at = val
        },
        updateName() {
            //generate slug
            this.$emit("input");
        },
        updateContent(val) {
            this.detailAnswer.content = val;
        },
        updateParent() {
            this.dialog = true;
        },
        update() {
            this.$emit("input");
        },
        isRealValue(obj) {
            return obj && obj !== 'null' && obj !== 'undefined';
        }
        ,
        convertNumberToString(number) {
            if (this.isRealValue(number)) {
                return number.toString();
            }
            return "0";
        },
        renderMathJax() {
            if (window.MathJax) {
                window.MathJax.Hub.Config({
                    tex2jax: {
                        inlineMath: [['$', '$'], ['\\(', '\\)']],
                        displayMath: [['$$', '$$'], ['[', ']']],
                        processEscapes: true,
                        processEnvironments: true
                    },
                    // Center justify equations in code and markdown cells. Elsewhere
                    // we use CSS to left justify single line equations in code cells.
                    displayAlign: 'center',
                    'HTML-CSS': {
                        styles: {'.MathJax_Display': {margin: 0}},
                        linebreaks: {automatic: true}
                    },
                    ...this.options
                })
                window.MathJax.Hub.Queue([
                    'Typeset',
                    window.MathJax.Hub,
                    this.$refs.mathJaxEl
                ])
            }
        }
    }
    ,
}
;
</script>

<style lang="scss">
</style>
